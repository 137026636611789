import React from 'react';

import Spinner, { SpinnerHolder } from 'components/Spinner';
import { useRedirectToAuth0 } from 'components/UniversalLogin/useRedirectToAuth0';
import { RTEStatusEnum, RTETriggerPointEnum } from 'constants/status.enum';
import { useRTEConfigs } from 'features/useRTEConfigs';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Store } from 'types/store';
import { isFromMobile, toMobileCallbackUrl, toWebCallbackUrl } from 'types/universal-login.utils';
import { InterstitialProps } from '../types';
import { GettingStarted } from './GettingStarted';

export const Progress = ({
  nextQuestion,
  step: currentStep
}: Omit<InterstitialProps, 'offeringKey'> & { step: 1 | 2 | 3 | 4 }) => {
  const history = useHistory();
  const user = useSelector(({ global_reducer: { current_user } }: Store) => current_user);
  const userRegion = user.attributes.patient?.region || '';
  const { data: rteConfigs, isLoading: isRteConfigsLoading } = useRTEConfigs({ state: userRegion });
  const redirectToAuth0 = useRedirectToAuth0();

  const webOrMobileCallback = isFromMobile() ? toMobileCallbackUrl : toWebCallbackUrl;

  const handleContinue = async () => {
    if (currentStep === 1 && sessionStorage.getItem('anonymous_id')) {
      redirectToAuth0({ returnTo: webOrMobileCallback(), screen_hint: 'signup' });
      return;
    }

    if (
      currentStep === 2 &&
      (sessionStorage.getItem('ob_flow') === 'insurance_eligibility_check' ||
        user.attributes.patient?.rte_status === RTEStatusEnum.PRE_RTE)
    ) {
      const isEnabledRteState = userRegion && rteConfigs.global_rte_enabled && rteConfigs.states?.[0]?.rte_enabled;

      if (isEnabledRteState) {
        sessionStorage.setItem('rteTriggerPoint', RTETriggerPointEnum.ONBOARDING);
        history.push('/patient/insurance-coverage');
      } else {
        nextQuestion();
      }
    } else {
      nextQuestion();
    }
  };

  if (currentStep === 2 && isRteConfigsLoading) {
    return (
      <SpinnerHolder>
        <Spinner />
      </SpinnerHolder>
    );
  }

  return <GettingStarted currentStep={currentStep} handleContinue={handleContinue} />;
};
