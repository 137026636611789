import { useApi } from 'utils/api';

/**
 * Custom hook for managing subscription-related operations.
 *
 * This hook provides methods for interacting with the API to handle subscription-related tasks,
 * such as creating a Setup Intent for payment methods and updating the default payment method.
 *
 * @function useSubscriptionMethods
 * @returns {Object} An object containing the following methods:
 * @property {Function} createIntent - Creates a Setup Intent for a payment method.
 * @property {Function} updateDefaultPaymentMethod - Updates the default payment method for a patient.
 *
 * @example
 * const { createIntent, updateDefaultPaymentMethod } = useSubscriptionMethods();
 *
 * // Create a Setup Intent
 * const handleCreateIntent = async () => {
 *   const result = await createIntent();
 *   if (result.error) {
 *     console.error('Failed to create Setup Intent:', result.message);
 *   } else {
 *     console.log('Setup Intent created successfully:', result.data);
 *   }
 * };
 *
 * // Update the default payment method
 * const handleUpdatePaymentMethod = async () => {
 *   const paymentMethodId = 'pm_12345';
 *   const isDefault = true;
 *   try {
 *     await updateDefaultPaymentMethod(paymentMethodId, isDefault);
 *     console.log('Default payment method updated successfully');
 *   } catch (error) {
 *     console.error('Failed to update default payment method:', error);
 *   }
 * };
 */
export const usePaymentMethods = () => {
  const api = useApi();

  /**
   * Creates a Setup Intent for the payment method.
   *
   * This function sends a POST request to the API to create a Setup Intent, which is used to
   * confirm the payment method during the onboarding process. The Setup Intent is a Stripe object
   * that represents the intent to set up a payment method for future payments.
   *
   * @async
   * @function createIntent
   * @returns {Promise<Object>} A promise that resolves to an object containing the Setup Intent data
   *                            or an error message.
   * @property {boolean} error - Indicates whether the request was successful (`false`) or failed (`true`).
   * @property {string} message - A message describing the result of the request.
   * @property {Object|null} data - The Setup Intent data returned by the API if the request was successful,
   *                                or `null` if it failed.
   *
   * @example
   * const { createIntent } = useSubscriptionMethods();
   * const result = await createIntent();
   * if (result.error) {
   *   console.error('Failed to create Setup Intent:', result.message);
   * } else {
   *   console.log('Setup Intent created successfully:', result.data);
   * }
   */
  const createIntent = async () => {
    try {
      const response = await api.post('api/v2/payments/setup_intents', {});
      return {
        error: false,
        message: response.data.message,
        data: response.data.data // setup intent object
      };
    } catch (error: unknown) {
      return {
        error: true,
        message: 'Failed to create Setup Intent',
        data: null
      };
    }
  };

  /**
   * Updates the default payment method for a patient.
   *
   * This function sends a PUT request to the API to update the default payment method for the patient.
   *
   * @function updateDefaultPaymentMethod
   * @param {string} paymentMethodId - The ID of the payment method to be updated.
   * @param {boolean} is_default - Whether the payment method should be set as the default.
   * @returns {Promise<void>} A promise that resolves when the payment method is successfully updated.
   *
   * @example
   * const { updateDefaultPaymentMethod } = useSubscriptionMethods();
   * const paymentMethodId = 'pm_12345';
   * const isDefault = true;
   * try {
   *   await updateDefaultPaymentMethod(paymentMethodId, isDefault);
   *   console.log('Default payment method updated successfully');
   * } catch (error) {
   *   console.error('Failed to update default payment method:', error);
   * }
   */
  const updateDefaultPaymentMethod = (paymentMethodId: string, is_default: boolean) => {
    return api.put(`/api/v2/payments/methods/${paymentMethodId}`, { is_default });
  };

  return {
    createIntent,
    updateDefaultPaymentMethod
  };
};
