export const WAITLIST_EVENTS = {
  VIEWED: 'Viewed the waitlist',
  SUBSCRIBED: 'Subscribed the waitlist',
  CHECKED_OUT: 'Checked out (waitlist)',
  BOOKED_PRESCRIBER_US: 'Booked prescriber in the onboarding flow (waitlist)',
  BOOKED_PRESCRIBER_UK: 'Booked private GP in the onboarding flow (waitlist)',
  CLICKED_SCHEDULED_LATER: 'Clicked on schedule later (waitlist)'
};

export const RTE_V2_EVENTS = {
  PASS: 'RTE V2 Passed',
  FAIL: 'RTE V2 Failed',
  PLAN_NOT_COVER: 'Plan doesn’t cover',
  NOT_ELIGIBLE: 'Out of network',
  ERROR_RESPONSE: 'RTE V2 check failed',
  AVAILABLE_CAPACITY: 'Available capacity',
  UNAVAILABLE_CAPACITY: 'Unavailable capacity',
  VIEW_CONFIRM_INI_PLAN: 'View INI Plan update page',
  VIEW_INSURANCE_DETAIL: 'View  Insurance details page',
  CLICK_CONTINUE_INSURANCE_DETAIL: 'Click Continue button - Insurance Details page',
  CLICK_SKIP_INSURANCE_DETAIL: 'Click Skip for now button - Insurance Details page',
  VIEW_INSURANCE_CARD: 'View Insurance card page',
  CLICK_CONTINUE_INSURANCE_CARD: 'Click Continue button - Insurance card page',
  CLICK_SKIP_INSURANCE_CARD: 'Click Skip for now button - Insurance card page',
  VIEW_INSURANCE_CONFIRM: 'View Insurance confirmation page',
  CLICK_VERIFY_ELIGIBILITY: 'Click Verify Eligibility button',
  SWITCH_INI_PLAN: 'Switch from Cash Plan to  INI Plan'
};

export const SIGN_UP_LINK_EVENT = {
  NOT_AVAILABLE_VIEW: 'View - Clinician is not available pop-up',
  NOT_AVAILABLE_CLICK_VIEW_OTHER: 'Click - View other clinicians button from Clinician is not available pop-up',
  NOT_AVAILABLE_CLICK_CANCEL: 'Click - Cancel button from Clinician is not available pop-up',
  NO_LONGER_AVAILABLE_CLICK_NO: 'Click - No button from Clinician is no longer available pop-up',
  REFERRAL_SUCCESS: 'Referral success',
  REFERRAL_FAIL: 'Referral fail'
};

export const MED_99_EVENTS = {
  PURCHASED: 'Med-mgmt-99-Sub-Purchase',
  UPSELL: 'meds-counseling-99-to-meds-therapy-upsell'
};

export const MEDICAID_ACKNOWLEDGEMENT_EVENTS = {
  VIEW_BENEFICIARY_POPUP: 'View - Beneficiary popup',
  CONFIRM_AND_CONTINUE_IN_BENEFICIARY_POPUP: 'Click - Confirm and continue in Beneficiary popup',
  CLICK_I_AM_A_BENEFICIARY: 'Click - I am a beneficiary in Beneficiary popup',
  VIEW_SUBSCRIPTION_CANCELLATION: 'View - Subscription cancellation for beneficiary popup',
  CLICK_SUBSCRIPTION_CANCELLATION: 'Click - Cancel and refund in Subscription cancellation for beneficiary popup',
  CLICK_GO_BACK: 'Click - Go back in Subscription cancellation for beneficiary popup',
  VIEW_CANCEL_AND_REFUND_PAGE: 'View - Cancel and refund page for beneficiary'
};

export const SELECT_PLAN_EVENT = {
  CLICK_THERAPY_PLAN: 'Clicked - Start therapy at Select plan page',
  CLICK_MEDS_THERAPY: 'Clicked - Start Meds+Therapy at Select plan page',
  CLICK_ADHOC_THERAPY: 'Clicked - Adhoc Therapy at Select plan page'
};

export const PERSONA_V4_EVENT = {
  OPEN_PERSONA_MODAL: '[Persona] View Getting started page',
  VERIFICATION_COMPLETE: '[Persona] Click Done - You are done modal',
  NEEDS_REVIEW_STATUS: '[Cerebral.com] View - /id-needs-review',
  DECLINED_STATUS: '[Cerebral.com] View - /id-declined',
  APPROVED_STATUS: 'View - /id-completed'
};

export const MMSP_EVENT = {
  STRONG_START_SELECTED: 'strong_start_selected'
};

export const ANXIETY_ASSESSMENT_RESULT_CONTINUE_BUTTON = 'Click - Anxiety Assessment Result Continue Button';
export const DEPRESSION_ASSESSMENT_RESULT_CONTINUE_BUTTON = 'Click - Depression Assessment Result Continue Button';

export const MED_UPSELL_EVENTS = {
  CLICK_ADD_THERAPY: 'upsell_clicked - Add Therapy button',
  CLICK_CONTINUE_MEDICATION: 'upsell_clicked - Continue with Medication button',
  OPT_OUT_OF_UPSELL: 'upsell_opted_out - Close upsell modal button'
};

export const HOW_TO_PAY_EVENTS = {
  // First variant in HowWouldYouLikeToPay
  PAY_WITH_INSURANCE_EQUAL_PREFERENCE: 'Pay with insurance',
  PAY_OUT_OF_POCKET_EQUAL_PREFERENCE: 'Pay OOP',
  // Second variant in PayWithInsurance
  PAY_WITH_INSURANCE_INI_PREFERENCE: 'Pay with insurance/Continue',
  PAY_OUT_OF_POCKET_INI_PREFERENCE: 'I’d rather pay OOP '
};

export const CLINICIAN_POST_CHECKOUT_EVENTS = {
  CHOOSE_THERAPIST_INTERSTITIAL: 'Clicked - Continue on Choose Therapist Interstitial',
  CHOOSE_PRESCRIBER_INTERSTITIAL: 'Clicked - Continue on Choose Prescriber Interstitial',
  WHAT_IS_THERAPIST: 'Clicked - What is a Therapist',
  WHAT_IS_PRESCRIBER: 'Clicked - What is a Prescriber',
  CLICK_BIO_THERAPIST: 'Clicked - Read Bio for Therapist',
  CLICK_BIO_PRESCRIBER: 'Clicked - Read Bio for Prescriber',
  VIEWED_THERAPIST_LIST: 'Viewed Therapist List',
  VIEWED_PRESCRIBER_LIST: 'Viewed Prescriber List',
  TIME_SLOTS_THERAPIST: 'Viewed time slots for Therapist',
  TIME_SLOTS_PRESCRIBER: 'Viewed time slots for Prescriber',
  SUBMIT_APPOINTMENT_THERAPIST: 'Viewed appointment confirmation for Therapist',
  SUBMIT_APPOINTMENT_PRESCRIBER: 'Viewed appointment confirmation for Prescriber'
};
