import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useApi } from 'utils/api';
import { useConfig } from 'contexts/ConfigContext';
import type { ProgressiveResponse } from '../types';

export type FlagData = Record<string, boolean>;

const STALE_TIME = process.env.NODE_ENV !== 'test' ? 1000 * 60 * 60 : 0;

// possible arg types: typed params (patient_id, flag)
export const useFeatureFlags = (
  params?: Record<string, string | number | undefined | null>
): ProgressiveResponse<FlagData> => {
  const { REACT_APP_DISABLE_AUTH_0_OVERRIDE } = useConfig();
  const api = useApi();

  const { data, status, isLoading, isSuccess } = useQuery(
    ['featureFlags', ...(params ? Object.entries(params).map(([key, value]) => `${key}:${value}`) : [])],
    () => api?.get<FlagData>('/api/v1/feature_flags', params ? { params } : {}),
    {
      enabled: !!api,
      staleTime: STALE_TIME
    }
  );

  return useMemo(
    () => ({
      status,
      isLoading,
      isSuccess,
      data: {
        ...data?.data,
        auth_userlogin_auth0: REACT_APP_DISABLE_AUTH_0_OVERRIDE ? false : !!data?.data?.auth_userlogin_auth0
      }
    }),
    [data, params, isLoading]
  );
};
