import { SUBMIT_COUPLES_THERAPY_STATE_QUESTION, SUBMIT_COUPLES_THERAPY_EXCLUSIONS_QUESTION } from 'utils/segment';
import { QueryToCookieConverter } from './shared/QueryToCookieConverter/QueryToCookieConverter';

export const getSegmentEvent = (questionName: string) => {
  switch (questionName) {
    default:
      return undefined;
  }
};

export const getSubmitSegmentEvent = (questionName: string) => {
  switch (questionName) {
    case 'couples_therapy_state':
      return SUBMIT_COUPLES_THERAPY_STATE_QUESTION;
    case 'couples_therapy_exclusions':
      return SUBMIT_COUPLES_THERAPY_EXCLUSIONS_QUESTION;
    default:
      return undefined;
  }
};

export const writeClickIdsToCookie = () => {
  new QueryToCookieConverter('ttclid').setCookieParamFromQueryString();
  new QueryToCookieConverter('fbclid').setCookieParamFromQueryString();
  new QueryToCookieConverter('gclid').setCookieParamFromQueryString();
  new QueryToCookieConverter('d').setCookieParamFromQueryString('awclid');
  new QueryToCookieConverter('ref_id').setCookieParamFromQueryString('saclid');
};

export const getFlowBySelectedPlan = (
  selectedPlan: string | null,
  isInsurance: boolean,
  isAffordableMeds: boolean
): string => {
  if (isAffordableMeds && ['medication', 'medication_99'].includes(selectedPlan as string)) {
    return 'affordable_meds_v1';
  }

  const flow = selectedPlan === 'couples_therapy' ? 'couples_v1' : 'individual_v1';
  return isInsurance ? 'individual_ini_v1' : flow;
};
