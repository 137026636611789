import { useApi } from 'utils/api';
import tracking from 'utils/tracking';

/**
 * Custom hook to handle subscription-related operations for a patient.
 *
 * This hook provides methods to create and synchronize subscriptions,
 * leveraging the API and tracking utilities. It abstracts the logic
 * for managing subscriptions, making it reusable across the application.
 *
 * @param {number} patientId - The ID of the patient for whom the subscription operations will be performed.
 * @returns {Object} An object containing the following methods:
 * - createSubscription: Creates a new subscription for a patient.
 * - syncSubscription: Synchronizes an existing subscription for a patient.
 *
 * @example
 * const { createSubscription, syncSubscription } = useSubscriptionHandler();
 * const result = await createSubscription({ patient_plan_price_id: 12345 });
 * if (!result.error) {
 *   console.log('Subscription created:', result.data);
 * }
 */
export const useSubscriptionHandler = (patientId: number) => {
  const api = useApi();

  /**
   * Creates a subscription for a patient with the provided options.
   *
   * This function sends a POST request to the API to create a subscription for the specified patient.
   * It supports optional parameters such as a coupon code and a payment method ID.
   *
   * @async
   * @function createSubscription
   * @param {Object} options - The options for creating the subscription.
   * @param {number} options.patient_plan_price_id - The ID of the patient plan price.
   * @param {string} [options.coupon_code] - An optional coupon code to apply to the subscription.
   * @param {string} [options.payment_method_id] - An optional payment method ID to associate with the subscription.
   * @returns {Promise<Object>} A promise that resolves to an object containing the subscription data or an error message.
   * @property {boolean} error - Indicates whether the request was successful (`false`) or failed (`true`).
   * @property {string} message - A message describing the result of the request.
   * @property {Object|null} data - The subscription data returned by the API if the request was successful, or `null` if it failed.
   *
   * @example
   * const { createSubscription } = useSubscriptionsV2();
   * const result = await createSubscription({
   *   patient_plan_price_id: 12345,
   *   coupon_code: 'DISCOUNT2025',
   *   payment_method_id: 'pm_12345'
   * });
   * if (result.error) {
   *   console.error('Failed to create subscription:', result.message);
   * } else {
   *   console.log('Subscription created successfully:', result.data);
   * }
   */
  const createSubscription = async (options: {
    patient_plan_price_id: number;
    coupon_code?: string;
    payment_method_id?: string;
  }) => {
    try {
      const response = await api.post(`api/v3/patients/${patientId}/subscriptions`, options);
      return {
        error: false,
        message: response.data.message,
        data: response.data
      };
    } catch (error: unknown) {
      tracking.errorV2('Failed to create subscription', error as Error, { tags: ['useSubscriptionsV2', 'error'] });
      return {
        error: true,
        message: 'Failed to create subscription',
        data: null
      };
    }
  };

  /**
   * Synchronizes a subscription for a patient.
   *
   * This function sends a PUT request to the API to synchronize the specified subscription for the patient.
   *
   * @async
   * @function syncSubscription
   * @param {string|number} subscriptionId - The ID of the subscription to be synchronized.
   * @returns {Promise<Object>} A promise that resolves to an object containing the synchronization result or an error message.
   * @property {boolean} error - Indicates whether the request was successful (`false`) or failed (`true`).
   * @property {string} message - A message describing the result of the request.
   * @property {Object|null} data - The synchronization data returned by the API if the request was successful, or `null` if it failed.
   *
   * @example
   * const { syncSubscription } = useSubscriptionsV2();
   * const result = await syncSubscription('sub_12345');
   * if (result.error) {
   *   console.error('Failed to sync subscription:', result.message);
   * } else {
   *   console.log('Subscription synchronized successfully:', result.data);
   * }
   */
  const syncSubscription = async (subscriptionId: string | number) => {
    try {
      const response = await api.put(`api/v3/patients/${patientId}/subscriptions/${subscriptionId}`, {});
      return {
        error: false,
        message: response.data.message,
        data: response.data
      };
    } catch (error: unknown) {
      tracking.errorV2('Failed to sync subscription', error as Error, { tags: ['useSubscriptionsV2', 'error'] });
      return {
        error: true,
        message: 'Failed to sync subscription',
        data: null
      };
    }
  };

  return {
    createSubscription,
    syncSubscription
  };
};
