import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { sign_out, update_user_anonymous_id, set_user } from 'actions/user_auth_action';
import { Store } from 'types/store';
import { parse } from 'query-string';
import { useHistory, useLocation } from 'react-router';
import { useApi } from 'utils/api';
import {
  getOnboardingStatus,
  get_patient_details,
  setMedicationWaitlist,
  update_patient,
  setShowRteModal,
  create_rte_status
} from 'actions/patient_action';
import { PromoCode } from 'components/Onboarding/Checkout/Payment/OrderDetails/PromoCodeForm/PromoCode';
import { useLoginSuccess } from 'components/Onboarding/shared';
import { NB } from '@cerebral-inc/ui';
import { mapResponseToError } from 'utils/common';
import tracking from 'utils/tracking';
import segment, { SCREENERS_ACC_CREATION_PLACEHOLDER } from 'utils/segment';
import { IOnboardingStatus } from 'components/Onboarding/shared/useOnboardingStatus/type';
import {
  isFromMobile,
  isMobileLoggedInSuccess,
  fromScreenHintParam,
  toMobileCallbackUrl,
  toMobileLoggedInUrl,
  toWebCallbackUrl,
  redirectToPreSignup,
  fromNextStepParam,
  getNextPathFromSessionStorage,
  saveNextPathToSessionStorage,
  removeRedirectLinkToSessionStorage,
  getRedirectLinkFromSessionStorage,
  saveRedirectLinkToSessionStorage,
  getRteResetLocation,
  getScreenerTypeFromSessionStorage
} from 'types/universal-login.utils';
import { SOMETHING_WENT_WRONG_MESSAGE } from 'utils/errorCode';
import { PRE_SIGNUP_BASE_PATH } from 'components/Onboarding/PreSignupFlow/QuestionManager/constant';
import zipCodeToState from 'utils/zipCodeToState';
import { useCoreMarketingSegmentEvents } from 'utils/hooks/useCoreMarketingSegmentEvents';
import { isAxiosError } from 'axios';
import { Spinner } from '@cerebral-inc/design-system';
import { iterableIntegrations } from '../Onboarding/utils';
import purple_logo from './images/purple_logo.svg';
import { useRedirectToAuth0 } from './useRedirectToAuth0';
import useIsSignedIn from './useIsSignedIn';

const UniversalLogin = () => {
  const dispatchRedux = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const redirectToAuth0 = useRedirectToAuth0();
  const user = useSelector((state: Store) => state.global_reducer.current_user.attributes);
  const { trackSignUp } = useCoreMarketingSegmentEvents();
  const location = useLocation();
  const history = useHistory();
  const api = useApi();
  const { isSignedIn } = useIsSignedIn();
  const { state: locationState, search } = location;
  const urlParams = parse(search);
  const { redirectPath } = useLoginSuccess();
  const { Flex, Text, Box, Button } = NB;
  const [errorMessage, setErrorMessage] = useState('');
  const [auth0AccessToken, setAuth0AccessToken] = useState('');
  const [segmentTriggered, setSegmentTriggered] = useState(false);
  const [successfulLogin, setSuccessfulLogin] = useState(false);

  const getUserDetail = async (token: string) => {
    const response = await api.get('/api/users/id/whoami', {
      headers: { auth0AccessToken: `Bearer ${token}`, 'Content-Type': 'application/json' },
      baseURL: process.env.REACT_APP_API_SERVER_API_GATEWAY_URL
    });

    const { data } = response.data;
    data.auth0AccessToken = token;
    await dispatchRedux(set_user(data));
  };

  // save locationState to sessionStorage to avoid losing it when redirect to auth0
  useEffect(() => {
    try {
      if (locationState?.next) {
        saveNextPathToSessionStorage(locationState);
        return;
      }

      if (window.location.search?.includes('screenerScore')) {
        const assessmentResultLink = { next: '/patient/assessment-result' };
        saveNextPathToSessionStorage(assessmentResultLink);
      }

      if (window.location.search?.includes('survey')) {
        const rootScreenerUrl = `${process.env.REACT_APP_MAIN_PAGE_URL}/screeners`;
        const surveyMap = new Map(
          Object.entries({
            adhd: {
              redirectPath: `${rootScreenerUrl}/adhd-self-test`,
              type: 'adhd'
            },
            anxiety: {
              redirectPath: `${rootScreenerUrl}/anxiety-self-test`,
              type: 'anxiety'
            },
            anxiety1: {
              redirectPath: `${rootScreenerUrl}/anxiety-assessment`,
              type: 'anxiety'
            },
            depression: {
              redirectPath: `${rootScreenerUrl}/depression-self-test`,
              type: 'depression'
            },
            depression1: {
              redirectPath: `${rootScreenerUrl}/depression-assessment`,
              type: 'depression'
            },
            bipolar: {
              redirectPath: `${rootScreenerUrl}/bipolar-self-test`,
              type: 'bipolar'
            },
            bipolar1: {
              redirectPath: `${rootScreenerUrl}/bipolar-assessment`,
              type: 'bipolar'
            }
          })
        );

        const searchParams = new URLSearchParams(window.location?.search);
        const surveyType = searchParams.get('survey') || '';
        if (surveyMap.get(surveyType)) {
          const screenerObject = surveyMap.get(surveyType);
          if (screenerObject) {
            saveRedirectLinkToSessionStorage(screenerObject);
          }
        }
      }
    } catch (error) {
      const message = mapResponseToError(error);
      tracking.errorV2('Universal login error', error, {
        tags: ['universal-login', 'error'],
        message
      });
    }
  }, [locationState]);

  const onSuccessfulLogin = async (skipPatientDetails = false) => {
    if (user.role !== 'patient') {
      return null;
    }

    if (urlParams?.waitlist?.includes('medication')) {
      api.post('api/waitlists/enable_on_waitlist_checkout_session').then(() => {
        dispatchRedux(
          setMedicationWaitlist({
            offering_key: urlParams?.waitlist,
            status: 'available',
            is_click_link: true,
            heap_tracking: true
          })
        );
      });
    } else {
      dispatchRedux(setMedicationWaitlist({ is_click_waitlist_link: false, heap_tracking: false }));
    }
    new PromoCode().setCodeFromQueryString();

    const webRedirectPath = getRedirectLinkFromSessionStorage();
    if (webRedirectPath) {
      if (getScreenerTypeFromSessionStorage()) {
        segment.track(
          `${SCREENERS_ACC_CREATION_PLACEHOLDER} ${getScreenerTypeFromSessionStorage()}`,
          {},
          iterableIntegrations
        );
      }
      removeRedirectLinkToSessionStorage();

      window.location.replace(webRedirectPath);
    }
    const nextPath = getNextPathFromSessionStorage();

    const data = await dispatchRedux(getOnboardingStatus(nextPath, api));
    let redirectUrl = (data as unknown as IOnboardingStatus)?.resume_url || redirectPath;

    if (getRteResetLocation(redirectUrl).isRteModalShown) {
      dispatchRedux(setShowRteModal(true));
    }
    redirectUrl = getRteResetLocation(redirectUrl).resumeUrl;

    history.push(`${redirectUrl}${window.location.search}`, {
      ...nextPath,
      skipPatientDetails
    });

    return null;
  };

  const handleSegment = async (id: number | null, email: string | null, first_sign_in?: boolean) => {
    try {
      if (id && email && segmentTriggered === false) {
        segment.identify(id, email);
        setSegmentTriggered(true);
        if (first_sign_in) {
          trackSignUp({
            email
          });
        }
      }
    } catch (error) {
      tracking.errorV2('Error in segment tracking', error, {
        tags: ['error', 'segment', 'universal-login'],
        id,
        email,
        firstSignIn: first_sign_in
      });
    }
  };

  const updateAnonymousId = async (email: string | null) => {
    const anonymousId = sessionStorage.getItem('anonymous_id');
    if (email && anonymousId) {
      await dispatchRedux(update_user_anonymous_id(api, email, anonymousId));
    }
  };

  const updatePreSignUpPatientInfo = async (isFirstLogin: boolean | undefined) => {
    if (isFirstLogin) {
      const name = sessionStorage.getItem('pre-signup-name');
      const postalCode = sessionStorage.getItem('pre-signup-postal-code');
      if (name && postalCode) {
        await dispatchRedux(
          update_patient(api, {
            preferred_name: name,
            postal_code: postalCode,
            region: zipCodeToState(Number(postalCode))
          })
        );
        sessionStorage.removeItem('pre-signup-name');
        sessionStorage.removeItem('pre-signup-postal-code');
      }
    }
  };

  const createRTEStatus = async (skipCreate: boolean) => {
    if (sessionStorage.getItem('ob_flow') === 'insurance_eligibility_check' && !skipCreate) {
      await dispatchRedux(create_rte_status(api));
    }
  };

  const emitMobileLoginSuccess = (token: string) => {
    if (isMobileLoggedInSuccess() && !!token) {
      window.location.replace(toMobileLoggedInUrl(token));
      return true;
    }

    return false;
  };

  const universalLogin = async () => {
    try {
      const authToken = await getAccessTokenSilently();
      if (!authToken) throw new Error('No token found');
      try {
        setAuth0AccessToken(authToken);
        if (emitMobileLoginSuccess(authToken)) return;
        await getUserDetail(authToken);
        setSuccessfulLogin(true);
      } catch (error) {
        let message = SOMETHING_WENT_WRONG_MESSAGE;
        if (isAxiosError(error) && error?.response?.data?.message) {
          message = error.response.data.message;
        }

        tracking.errorV2('Universal login has error', error, { tags: ['universal-login', 'error'], message });
        setErrorMessage(message);
      }
    } catch (e) {
      let shouldRedirectToPresignupFlow = false;
      const screenHint = fromScreenHintParam();
      const nextStep = fromNextStepParam();
      if (screenHint === 'signup' && nextStep !== 'signup') {
        shouldRedirectToPresignupFlow = true;
      }

      if (shouldRedirectToPresignupFlow) {
        history.push(redirectToPreSignup(PRE_SIGNUP_BASE_PATH));
        return;
      }

      redirectToAuth0({
        returnTo: window.location.href,
        screen_hint: screenHint
      });
    }
  };

  const signin = async () => {
    const signedIn = await isSignedIn();
    if (signedIn) {
      setSuccessfulLogin(true);
    } else {
      await universalLogin();
    }
  };

  const universalLoginRedirect = (screen_hint?: string) => {
    window.onbeforeunload = null;
    redirectToAuth0({
      returnTo: toWebCallbackUrl(),
      screen_hint
    });
  };

  useEffect(() => {
    if (!successfulLogin) return;
    if (emitMobileLoginSuccess(auth0AccessToken)) return;
    (async () => {
      await dispatchRedux(get_patient_details(api));
      handleSegment(user.id, user.email, user.first_sign_in);
      await createRTEStatus(!!user.rte_status);
      updateAnonymousId(user.email);
      await updatePreSignUpPatientInfo(user.first_sign_in);
      await onSuccessfulLogin(true);
    })();
  }, [successfulLogin]);

  useEffect(() => {
    if (isFromMobile()) {
      dispatchRedux(sign_out());
      redirectToAuth0({
        returnTo: toMobileCallbackUrl(),
        screen_hint: fromScreenHintParam()
      });
      return;
    }

    (async () => {
      await signin();
    })();
  }, []);

  if (errorMessage)
    return (
      <>
        <Box alignSelf="center" margin="50px">
          <img className="cerebral-logo" src={purple_logo} alt="Cerebral logo" />
        </Box>
        <Flex direction="row" marginTop="40px" marginBottom="60px" justifyContent="center" alignItems="center">
          <Text fontSize="xl" color="red.600">
            {errorMessage}
          </Text>
        </Flex>
        <Box alignItems="center" flexDirection="column">
          <Button
            fontFamily="Poppins"
            margin={0}
            marginBottom="10px"
            fontStyle="normal"
            fontWeight={600}
            fontSize="14px"
            lineHeight="16px"
            backgroundColor="#51459E"
            color="#FFFFFF"
            width="320px"
            borderRadius="28px"
            _hover={{ backgroundColor: '#51459E' }}
            onPress={() => universalLoginRedirect()}
          >
            Log in with another account
          </Button>
          <input
            className="btn-link"
            onClick={() => universalLoginRedirect('signup')}
            type="button"
            style={{ color: '#385ade', textDecoration: 'underline', fontSize: '14px' }}
            value="Create a new account."
          />
        </Box>
      </>
    );

  return <Spinner variant="screen" hideScreenOverlay />;
};

export default UniversalLogin;
