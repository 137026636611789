import { PromoCode } from 'components/Onboarding/Checkout/Payment/OrderDetails/PromoCodeForm/PromoCode';
import { QueryToCookieConverter } from 'components/Onboarding/shared/QueryToCookieConverter/QueryToCookieConverter';

export function toMobileLoggedInUrl(auth0Token: string): string {
  return `cerebralmobile://auth/token/${auth0Token}`;
}

export function toMobileLoggedInUrlWithRefreshToken(auth0AccessToken: string, auth0RefreshToken: string): string {
  return `cerebralmobile://auth/token/${auth0AccessToken}/${auth0RefreshToken}`;
}

export function toMobileLoggedOutUrl(): string {
  return 'cerebralmobile://profile-info/logout/success';
}

export function toMobileCallbackUrl(): string {
  return `${process.env.REACT_APP_AUTH0_REDIRECT_URI}?mobileLoggedInSuccess=true`;
}

export function toMobileLoginUrl(): string {
  return `${process.env.REACT_APP_AUTH0_REDIRECT_URI}/mobile-login`;
}

export function toWebCallbackUrl(): string {
  return `${process.env.REACT_APP_AUTH0_REDIRECT_URI}${window.location.search}`;
}

export function isFromMobile(): boolean {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('fromMobile') === 'true';
}

export function fromScreenHintParam(): string | undefined {
  const urlParams = new URLSearchParams(window.location.search);
  const screenHint = urlParams.get('screenHint');
  if (screenHint !== 'signup') return undefined;

  return screenHint;
}

export function fromNextStepParam(): string | null {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('nextStep');
}

export function isMobileLoggedInSuccess(): boolean {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('mobileLoggedInSuccess') === 'true';
}

export function getAuthorizationCode(): string | null {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('code');
}

export function redirectToPreSignup(url: string): string | undefined {
  const promoCodeInst = new PromoCode();
  const promoCode = promoCodeInst.getCode();
  let preSignupUrl = url;

  const ttclidInstance = new QueryToCookieConverter('ttclid');
  const ttclid = ttclidInstance.getCookieValue();

  const fbclidInstance = new QueryToCookieConverter('fbclid');
  const fbclid = fbclidInstance.getCookieValue();

  const gclidInstance = new QueryToCookieConverter('gclid');
  const gclid = gclidInstance.getCookieValue();

  const awclidInstance = new QueryToCookieConverter('d');
  awclidInstance.setCookieParamFromQueryString('awclid');
  const awclid = awclidInstance.getCookieValue();

  const msclkidInstance = new QueryToCookieConverter('msclkid');
  const msclkid = msclkidInstance.getCookieValue();

  const saclidInstance = new QueryToCookieConverter('ref_id');
  saclidInstance.setCookieParamFromQueryString('saclid');
  const saclid = saclidInstance.getCookieValue();

  const params = new URLSearchParams();
  if (ttclid) {
    params.append('ttclid', ttclid);
  }
  if (fbclid) {
    params.append('fbclid', fbclid);
  }
  if (promoCode) {
    params.append(promoCodeInst.getKey(), promoCode);
  }
  if (gclid) {
    params.append('gclid', gclid);
  }
  if (awclid) {
    params.append('awclid', awclid);
  }
  if (msclkid) {
    params.append('msclkid', msclkid);
  }
  if (saclid) {
    params.append('ref_id', saclid);
  }
  if (params.toString()) {
    preSignupUrl += `?${params.toString()}`;
  }

  return preSignupUrl;
}

export function useApiGateway(): boolean {
  return localStorage.getItem('useApiGateway') === 'true';
}

export const saveNextPathToSessionStorage = (locationState: Record<string, string>) => {
  sessionStorage.setItem('location-state', JSON.stringify(locationState));
};

export const getNextPathFromSessionStorage = (): Record<string, string> | null => {
  const locationStateString = sessionStorage.getItem('location-state');
  return locationStateString ? JSON.parse(locationStateString) : null;
};

export const saveRedirectLinkToSessionStorage = (screenerObject: { redirectPath: string; type: string }) => {
  sessionStorage.setItem('redirect-url', JSON.stringify(screenerObject.redirectPath));

  // Store screener type to session storage
  sessionStorage.setItem('screener-type', screenerObject.type);
};

export const getRedirectLinkFromSessionStorage = (): string => {
  const urlString = sessionStorage.getItem('redirect-url');
  return urlString ? JSON.parse(urlString) : null;
};

export const removeRedirectLinkToSessionStorage = () => {
  sessionStorage.removeItem('redirect-url');
  sessionStorage.removeItem('screener-type');
};

export const getScreenerTypeFromSessionStorage = () => {
  return sessionStorage.getItem('screener-type');
};

export const getRteResetLocation = (redirectUrl: string): { isRteModalShown: boolean; resumeUrl: string } => {
  const rteIndicator = '?show_rte_modal=';
  if (redirectUrl.includes(rteIndicator)) {
    return {
      isRteModalShown: redirectUrl.split(rteIndicator)[1] === 'true',
      resumeUrl: redirectUrl.split(rteIndicator)[0]
    };
  }

  return {
    isRteModalShown: false,
    resumeUrl: redirectUrl
  };
};
